const skills = {
    frontend: {
      title: 'Frontend Development',
      items: [
        {
          name: 'React',
          level: 90,
          description: 'Building complex, interactive UIs with React including hooks, context, and advanced patterns.'
        },
        {
          name: 'JavaScript',
          level: 95,
          description: 'Modern ES6+ JavaScript, asynchronous programming, functional programming patterns.'
        },
        {
          name: 'TypeScript',
          level: 85,
          description: 'Type-safe code with interfaces, generics, and advanced TypeScript features.'
        },
        {
          name: 'HTML5 & CSS3',
          level: 90,
          description: 'Semantic HTML, CSS Grid, Flexbox, animations, and responsive design.'
        },
        {
          name: 'Next.js',
          level: 85,
          description: 'Server-side rendering, static site generation, and API routes with Next.js.'
        },
        {
          name: 'Tailwind CSS',
          level: 90,
          description: 'Utility-first CSS framework for rapidly building custom designs.'
        },
        {
          name: 'Redux',
          level: 85,
          description: 'State management with Redux, including middleware and Redux Toolkit.'
        },
        {
          name: 'React Native',
          level: 75,
          description: 'Cross-platform mobile app development with React Native.'
        }
      ]
    },
    backend: {
      title: 'Backend Development',
      items: [
        {
          name: 'Node.js',
          level: 85,
          description: 'Building scalable servers and APIs with Node.js.'
        },
        {
          name: 'Express',
          level: 90,
          description: 'Creating RESTful APIs and web servers with Express.'
        },
        {
          name: 'Python',
          level: 80,
          description: 'Data processing, automation, and backend development with Python.'
        },
        {
          name: 'API Design',
          level: 90,
          description: 'Designing and documenting RESTful and GraphQL APIs.'
        }
      ]
    },
    database: {
      title: 'Database & Storage',
      items: [
        {
          name: 'MongoDB',
          level: 85,
          description: 'NoSQL database design, queries, and aggregation pipelines.'
        },
        {
          name: 'PostgreSQL',
          level: 80,
          description: 'Relational database design, complex queries, and optimization.'
        },
        {
          name: 'MySQL',
          level: 75,
          description: 'Database design, normalization, and SQL optimization.'
        },
        {
          name: 'Firebase',
          level: 90,
          description: 'Real-time database, authentication, and cloud functions.'
        },
        {
          name: 'Redis',
          level: 70,
          description: 'Caching, session management, and pub/sub with Redis.'
        }
      ]
    },
    devops: {
      title: 'DevOps & Deployment',
      items: [
        {
          name: 'Git & GitHub',
          level: 95,
          description: 'Version control, collaborative workflows, and CI/CD integration.'
        },
        {
          name: 'Docker',
          level: 80,
          description: 'Containerization of applications for consistent environments.'
        },
        {
          name: 'AWS',
          level: 75,
          description: 'Cloud deployment with EC2, S3, Lambda, and other AWS services.'
        },
        {
          name: 'CI/CD',
          level: 85,
          description: 'Automated testing and deployment pipelines with GitHub Actions and Jenkins.'
        },
        {
          name: 'Vercel & Netlify',
          level: 90,
          description: 'JAMstack deployment and serverless functions.'
        }
      ]
    },
    tools: {
      title: 'Tools & Methodologies',
      items: [
        {
          name: 'Testing',
          level: 85,
          description: 'Jest, React Testing Library, Cypress for comprehensive testing.'
        },
        {
          name: 'Agile',
          level: 90,
          description: 'Scrum and Kanban methodologies for project management.'
        },
        {
          name: 'Figma',
          level: 75,
          description: 'UI/UX design and developer-designer collaboration.'
        },
        {
          name: 'Webpack',
          level: 80,
          description: 'Module bundling, code splitting, and optimization.'
        },
        {
          name: 'Performance Optimization',
          level: 85,
          description: 'Web vitals, performance auditing, and optimization techniques.'
        }
      ]
    }
  };
  
  export default skills;