// Sample blog posts data
const posts = [
    {
      id: 1,
      title: 'Building Responsive Web Applications with React and Tailwind CSS',
      excerpt: 'Learn how to combine React and Tailwind CSS to create beautiful, responsive web applications that work flawlessly across all devices.',
      date: 'February 25, 2025',
      category: 'Development',
      tags: ['React', 'Tailwind CSS', 'Responsive Design'],
      image: '/blog-1.jpg', // You'll need to add these images to public folder
      readTime: '6 min read'
    },
    {
      id: 2,
      title: 'The Power of Server-Side Rendering with Next.js',
      excerpt: 'Explore how Next.js enhances web applications with improved performance, SEO benefits, and a better developer experience.',
      date: 'February 10, 2025',
      category: 'Development',
      tags: ['Next.js', 'SSR', 'React', 'Performance'],
      image: '/blog-2.jpg',
      readTime: '8 min read'
    },
    {
      id: 3,
      title: 'Creating Smooth Animations with Framer Motion',
      excerpt: 'Dive into Framer Motion and learn how to create smooth, physics-based animations that elevate the user experience of your React applications.',
      date: 'January 28, 2025',
      category: 'Design',
      tags: ['Animation', 'Framer Motion', 'React', 'UX'],
      image: '/blog-3.jpg',
      readTime: '5 min read'
    },
    {
      id: 4,
      title: 'Modern State Management in React: Beyond Redux',
      excerpt: 'Explore alternative state management approaches in React including Context API, Recoil, Zustand, and Jotai for different project needs.',
      date: 'January 15, 2025',
      category: 'Development',
      tags: ['React', 'State Management', 'Context API'],
      image: '/blog-4.jpg',
      readTime: '7 min read'
    },
    {
      id: 5,
      title: 'Building a REST API with Node.js and Express',
      excerpt: 'A step-by-step guide to creating robust RESTful APIs using Node.js and Express with best practices for authentication, validation, and error handling.',
      date: 'December 20, 2024',
      category: 'Backend',
      tags: ['Node.js', 'Express', 'REST API', 'Backend'],
      image: '/blog-5.jpg',
      readTime: '10 min read'
    },
    {
      id: 6,
      title: 'Getting Started with TypeScript in React Projects',
      excerpt: 'Learn how to integrate TypeScript into your React projects for type-safe code, better developer experience, and fewer runtime errors.',
      date: 'December 5, 2024',
      category: 'Development',
      tags: ['TypeScript', 'React', 'Type Safety'],
      image: '/blog-6.jpg',
      readTime: '9 min read'
    }
  ];
  
  export default posts;