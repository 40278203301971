// Sample project data
export const projects = [
    {
      id: 1,
      title: 'Book Bound',
      description: 'An interactive platform for keeping track of your personal books, featuring a library, trending section, challenges, and more.',
      image: '/', // You'll need to add these images to public folder
      tags: ['React', 'TailWindCss', 'MYSQL', 'HTML5', 'CSS3', 'TypeScript', 'JavaScript'],
      demoUrl: 'https://austin-mini-s-1.tailf2137e.ts.net',
      githubUrl: '/',
      featured: true
    }
  ];
  
  export const getProject = (id) => {
    return projects.find(project => project.id === parseInt(id));
  };
  
  export const getFeaturedProjects = () => {
    return projects.filter(project => project.featured);
  };
  
  export default projects;