import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';

const MacMenuBar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [time, setTime] = useState('');
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  
  // Update time every minute
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      let hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      
      const strMinutes = minutes < 10 ? '0' + minutes : minutes;
      
      setTime(`${hours}:${strMinutes} ${ampm}`);
    };
    
    updateTime(); // Initial call
    
    const timeInterval = setInterval(updateTime, 60000);
    return () => clearInterval(timeInterval);
  }, []);
  
  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const handleMenuClick = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };
  
  const closeMenu = () => {
    setActiveMenu(null);
  };
  
  // Menu data structure
  const menus = {
    apple: {
      title: '',
      items: [
        { label: 'About This Portfolio', action: () => {}, disabled: true },
        { divider: true },
        { label: isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode', action: toggleTheme },
        { divider: true },
        { label: 'Close', action: closeMenu }
      ]
    },
    file: {
      title: 'File',
      items: [
        { label: 'Home', action: () => navigate('/') },
        { label: 'Projects', action: () => navigate('/projects') },
        { label: 'Skills', action: () => navigate('/skills') },
        { label: 'Blog', action: () => navigate('/blog') },
        { divider: true },
        { label: 'Contact', action: () => navigate('/contact') }
      ]
    },
    edit: {
      title: 'Edit',
      items: [
        { label: 'Cut', action: () => {}, disabled: true },
        { label: 'Copy', action: () => {}, disabled: true },
        { label: 'Paste', action: () => {}, disabled: true },
        { divider: true },
        { label: 'Select All', action: () => {}, disabled: true }
      ]
    },
    view: {
      title: 'View',
      items: [
        { label: isDarkMode ? 'Light Mode' : 'Dark Mode', action: toggleTheme },
        { divider: true },
        { label: 'Show Hidden Files', action: () => {}, disabled: true }
      ]
    },
    special: {
      title: 'Special',
      items: [
        { label: 'GitHub', action: () => window.open('https://github.com/austinbarton1997', '_blank') },
        { label: 'LinkedIn', action: () => window.open('https://www.linkedin.com/in/austin-barton-909844268', '_blank') },
        { divider: true },
        { label: 'Contact Me', action: () => navigate('/contact') }
      ]
    }
  };
  
  return (
    <div className="mac-menu-bar" ref={menuRef}>
      {/* Apple Menu */}
      <div 
        className={`mac-apple-menu ${activeMenu === 'apple' ? 'mac-menu-active' : ''}`}
        onClick={() => handleMenuClick('apple')}
      >
        <span className="mac-apple-logo"></span>
      </div>
      
      {/* Regular Menus */}
      <div className="mac-menu-items">
        {Object.entries(menus).filter(([key]) => key !== 'apple').map(([key, menu]) => (
          <span 
            key={key}
            className={activeMenu === key ? 'mac-menu-active' : ''}
            onClick={() => handleMenuClick(key)}
          >
            {menu.title}
          </span>
        ))}
      </div>
      
      {/* Clock */}
      <div className="mac-menu-clock">
        {time}
      </div>
      
      {/* Menu Dropdowns */}
      {activeMenu && (
        <div className={`mac-menu-dropdown mac-${activeMenu}-dropdown`}>
          {menus[activeMenu].items.map((item, index) => {
            if (item.divider) {
              return <div key={`divider-${index}`} className="mac-menu-divider"></div>;
            }
            
            return (
              <div 
                key={`item-${index}`}
                className={`mac-menu-item ${item.disabled ? 'mac-menu-item-disabled' : ''}`}
                onClick={() => {
                  if (!item.disabled) {
                    item.action();
                    closeMenu();
                  }
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MacMenuBar;