import React, { useState } from 'react';
import MacWindow from '../components/ui/MacWindow';
import blogPosts from '../data/blog';
import { FiClock, FiCalendar, FiTag, FiFolder } from 'react-icons/fi';

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  
  // Extract all unique categories
  const categories = ['All', ...new Set(blogPosts.map(post => post.category))];
  
  // Filter posts based on selected category
  const filteredPosts = selectedCategory === 'All' 
    ? blogPosts 
    : blogPosts.filter(post => post.category === selectedCategory);

  return (
    <div className="mac-page mac-blog-page">
      <MacWindow 
        title="Blog.txt" 
        initialPosition={{ top: 40, left: 60 }}
        zIndex={3}
        className="mac-blog-main-window"
      >
        <div className="mac-window-inner">
          <h1 className="mac-page-title">Blog</h1>
          <p className="mac-page-subtitle">
            Thoughts, insights, and experiences from my journey as a developer.
          </p>
          
          <div className="mac-blog-categories">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`mac-category-button ${selectedCategory === category ? 'mac-category-button-active' : ''}`}
              >
                {category}
              </button>
            ))}
          </div>
          
          <div className="mac-blog-posts">
            {filteredPosts.length > 0 ? (
              filteredPosts.map(post => (
                <div key={post.id} className="mac-blog-post">
                  <div className="mac-blog-image">
                    {/* In a real app, use actual images */}
                    <div className="mac-blog-image-placeholder">
                      {post.title.split(' ').slice(0, 2).join(' ')}
                    </div>
                  </div>
                  
                  <div className="mac-blog-content">
                    <div className="mac-blog-meta">
                      <span className="mac-blog-meta-item">
                        <FiCalendar className="mac-blog-meta-icon" />
                        {post.date}
                      </span>
                      <span className="mac-blog-meta-item">
                        <FiClock className="mac-blog-meta-icon" />
                        {post.readTime}
                      </span>
                      <span className="mac-blog-meta-item">
                        <FiFolder className="mac-blog-meta-icon" />
                        {post.category}
                      </span>
                    </div>
                    
                    <h2 className="mac-blog-title">{post.title}</h2>
                    <p className="mac-blog-excerpt">{post.excerpt}</p>
                    
                    <div className="mac-blog-tags">
                      {post.tags.map((tag, index) => (
                        <span key={index} className="mac-blog-tag">
                          <FiTag className="mac-blog-tag-icon" />
                          {tag}
                        </span>
                      ))}
                    </div>
                    
                    <button className="mac-button mac-read-more-button">
                      Read More
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="mac-no-posts">
                <p>No posts found in this category.</p>
              </div>
            )}
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="Blog_Archives.txt" 
        initialPosition={{ top: 80, left: 100 }}
        zIndex={2}
        className="mac-blog-archives-window"
      >
        <div className="mac-folder-view">
          <div className="mac-folder-items">
            {categories.map(category => (
              <div 
                key={category}
                className={`mac-folder-item ${selectedCategory === category ? 'mac-folder-item-selected' : ''}`}
                onClick={() => setSelectedCategory(category)}
              >
                <div className="mac-folder-icon"></div>
                <div className="mac-folder-name">{category}</div>
              </div>
            ))}
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="Subscribe.form" 
        initialPosition={{ top: 120, left: 140 }}
        zIndex={1}
        className="mac-blog-subscribe-window"
      >
        <div className="mac-subscribe-form">
          <h3 className="mac-subscribe-title">Subscribe to my newsletter</h3>
          <p className="mac-subscribe-text">
            Get the latest articles and updates delivered to your inbox.
          </p>
          
          <div className="mac-form-group">
            <label htmlFor="subscribe-email" className="mac-form-label">
              Email Address:
            </label>
            <div className="mac-input-with-button">
              <input
                type="email"
                id="subscribe-email"
                className="mac-form-input"
                placeholder="your@email.com"
              />
              <button className="mac-button">
                Subscribe
              </button>
            </div>
          </div>
          
          <p className="mac-privacy-note">
            I respect your privacy and won't share your information.
          </p>
        </div>
      </MacWindow>
    </div>
  );
};

export default Blog;