import React, { useState } from 'react';
import { FiSend } from 'react-icons/fi';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
     // Using environment variables for EmailJS configuration
     const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
     const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
     const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    
    // template parameters
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
      to_name: 'Austin Barton',
      reply_to: formData.email
    };
    
    try {
      // Send the email using EmailJS
      const result = await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        publicKey
      );
      
      console.log('Email sent successfully:', result.text);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
      
      // Reset the success message after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    } catch (error) {
      console.error('Error sending email:', error);
      setSubmitStatus('error');
      
      // Reset the error message after 5 seconds
      setTimeout(() => {
        setSubmitStatus(null);
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mac-form">
      <div className="mac-form-group">
        <label htmlFor="name" className="mac-form-label">
          Full name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="mac-form-input"
          placeholder="John Doe"
        />
      </div>
      
      <div className="mac-form-group">
        <label htmlFor="email" className="mac-form-label">
          Email Address:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className="mac-form-input"
          placeholder="john@example.com"
        />
      </div>
      
      <div className="mac-form-group">
        <label htmlFor="message" className="mac-form-label">
          Your Message:
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          rows="6"
          className="mac-form-textarea"
          placeholder="Tell me about your project..."
        ></textarea>
      </div>
      
      <div className="mac-form-footer">
        <p className="mac-form-note">
          I respect your privacy and won't share your information.
        </p>
        <button
          type="submit"
          disabled={isSubmitting}
          className="mac-button"
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
          {!isSubmitting && <FiSend className="mac-button-icon" />}
        </button>
      </div>
      
      {submitStatus === 'success' && (
        <div className="mac-alert mac-alert-success">
          Thanks for your message! I'll get back to you soon.
        </div>
      )}
      
      {submitStatus === 'error' && (
        <div className="mac-alert mac-alert-error">
          Oops! Something went wrong. Please try again later.
        </div>
      )}
    </form>
  );
};

export default ContactForm;