import React from 'react';
import { Link } from 'react-router-dom';
import MacWindow from '../components/ui/MacWindow';

const NotFound = () => {
  return (
    <div className="mac-page mac-not-found-page">
      <MacWindow 
        title="Error.app" 
        initialPosition={{ top: 40, left: 60 }}
        zIndex={3}
        className="mac-error-window"
      >
        <div className="mac-error-content">
          <div className="mac-error-icon">
            <div className="mac-bomb-icon">💣</div>
          </div>
          
          <div className="mac-error-message">
            <h1 className="mac-error-title">404</h1>
            <h2 className="mac-error-subtitle">Page Not Found</h2>
            <p className="mac-error-text">
              Sorry, the file you're looking for might have been moved or deleted.
            </p>
            
            <div className="mac-error-dialog">
              <div className="mac-error-dialog-text">
                The system couldn't find the page you requested. 
                Would you like to go back to the home page?
              </div>
              <div className="mac-error-buttons">
                <Link to="/" className="mac-button">
                  OK
                </Link>
                <Link to="/" className="mac-button mac-button-outline">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="System.err" 
        initialPosition={{ top: 80, left: 100 }}
        zIndex={2}
        className="mac-system-error-window"
      >
        <div className="mac-text-file mac-error-log">
          <p>*** SYSTEM ERROR: 404 NOT FOUND ***</p>
          <p>ID=04-04</p>
          <p>URL cannot be located on this server.</p>
          <p>-----------------</p>
          <p>* Possible solutions:</p>
          <p>1. Check the URL for typos</p>
          <p>2. Return to the home page</p>
          <p>3. Try the navigation menu</p>
          <p>4. Contact the system administrator</p>
        </div>
      </MacWindow>
    </div>
  );
};

export default NotFound;