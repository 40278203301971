import React, { useState } from 'react';
import MacWindow from '../components/ui/MacWindow';
import skills from '../data/skills';

const Skills = () => {
  const [activeCategory, setActiveCategory] = useState('frontend');
  
  return (
    <div className="mac-page mac-skills-page">
      <MacWindow 
        title="Skills.txt" 
        initialPosition={{ top: 40, left: 60 }}
        zIndex={3}
        className="mac-skills-main-window"
      >
        <div className="mac-window-inner">
          <h1 className="mac-page-title">My Skills</h1>
          <p className="mac-page-subtitle">
            Click on the categories below to explore my technical skills
          </p>
          
          <div className="mac-skills-categories">
            {Object.keys(skills).map((category) => (
              <button
                key={category}
                className={`mac-button mac-category-button ${activeCategory === category ? 'mac-button-active' : ''}`}
                onClick={() => setActiveCategory(category)}
              >
                {skills[category].title}
              </button>
            ))}
          </div>
          
          <div className="mac-skills-viewer">
            <h2 className="mac-viewer-title">{skills[activeCategory].title}</h2>
            
            <div className="mac-skills-list">
              {skills[activeCategory].items.map((skill, index) => (
                <div key={index} className="mac-skill-item">
                  <div className="mac-skill-header">
                    <h3 className="mac-skill-name">{skill.name}</h3>
                    <div className="mac-skill-level-wrapper">
                      <div className="mac-skill-level-label">{skill.level}%</div>
                      <div className="mac-skill-level-bar">
                        <div 
                          className="mac-skill-level-fill"
                          style={{ width: `${skill.level}%` }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="mac-skill-description">{skill.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="Skill_Categories.txt" 
        initialPosition={{ top: 80, left: 100 }}
        zIndex={2}
        className="mac-skills-categories-window"
      >
        <div className="mac-folder-view">
          <div className="mac-folder-items">
            {Object.keys(skills).map((category) => (
              <div 
                key={category}
                className={`mac-folder-item ${activeCategory === category ? 'mac-folder-item-selected' : ''}`}
                onClick={() => setActiveCategory(category)}
              >
                <div className="mac-folder-icon"></div>
                <div className="mac-folder-name">{skills[category].title}</div>
              </div>
            ))}
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="README.txt" 
        initialPosition={{ top: 120, left: 140 }}
        zIndex={1}
        className="mac-skills-readme-window"
      >
        <div className="mac-text-file">
          <p>Austin's Technical Skills Overview</p>
          <p>---------------------------------</p>
          <p>This file contains a breakdown of my technical skills and expertise across different domains.</p>
          <p>• Browse through the categories to see detailed information about my skills</p>
          <p>• Each skill includes a proficiency level and description</p>
          <p>• Click on any category in the "Skill_Categories.txt" window to explore further</p>
          <p>• For more information, please contact me directly</p>
        </div>
      </MacWindow>
    </div>
  );
};

export default Skills;