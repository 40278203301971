import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MacMenuBar from './MacMenuBar';
import MacFooter from './MacFooter';
import MacContextMenu from '../../context/MacContextMenu';
// Import all your page components
import { Home, Skills, Projects, Blog, Contact } from '../../pages/';

const MacLayout = ({ children }) => {
  const [booted, setBooted] = useState(false);
  const [bootProgress, setBootProgress] = useState(0);
  const [currentPage, setCurrentPage] = useState('home');
  const location = useLocation();
  const navigate = useNavigate();
  
  // Simulate boot sequence on initial render
  useEffect(() => {
    if (!booted) {
      const bootSequence = setTimeout(() => {
        setBooted(true);
      }, 2000);
      
      // Progress bar animation
      const interval = setInterval(() => {
        setBootProgress(prev => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 5;
        });
      }, 100);
      
      return () => {
        clearTimeout(bootSequence);
        clearInterval(interval);
      };
    }
  }, [booted]);
  
  // Set current page based on location on initial load
  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      setCurrentPage('home');
    } else {
      // Remove the leading slash and set as current page
      setCurrentPage(path.substring(1));
    }
  }, [location]);

  // Handle icon clicks
  const handleIconClick = (page) => (e) => {
    e.preventDefault(); // Prevent default navigation
    
    // Show loading effect
    setBootProgress(0);
    const quickLoad = setTimeout(() => {
      setBootProgress(100);
    }, 500);
    
    // Update the current page without changing URL
    setCurrentPage(page);
    
    // We don't update the browser URL at all
    
    return () => clearTimeout(quickLoad);
  };

  // Render the appropriate component based on currentPage
  const renderContent = () => {
    switch(currentPage) {
      case 'home':
        return <Home />;
      case 'skills':
        return <Skills />;
      case 'projects':
        return <Projects />;
      case 'blog':
        return <Blog />;
      case 'contact':
        return <Contact />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="mac-theme">
      {/* Boot Screen (shows briefly before main content) */}
      {!booted && (
        <div className="mac-boot-screen">
          <div className="mac-boot-icon"></div>
          <div className="mac-boot-progress-container">
            <div 
              className="mac-boot-progress-bar" 
              style={{width: `${bootProgress}%`}}
            ></div>
          </div>
        </div>
      )}
      
      {/* Main Content */}
      {booted && (
        <div className="mac-computer-frame">
          {/* Mac Top Bezel with Apple Logo */}
          <div className="mac-top-bezel">
            <div className="mac-top-logo"></div>
          </div>
          
          {/* Screen Container */}
          <div className="mac-screen-container">
            {/* Mac Screen with content */}
            <div className="mac-screen">
              {/* Mac Menu Bar */}
              <MacMenuBar currentPage={currentPage} />
              
              {/* Desktop content */}
              <div className="mac-desktop-content">
                {/* Progress indicator for route changes */}
                {bootProgress < 100 && bootProgress > 0 && (
                  <div className="mac-route-loading">
                    <div className="mac-loading-icon"></div>
                  </div>
                )}
                
                {/* Page Content - Use renderContent() instead of children */}
                <div className={`mac-page-content ${bootProgress < 100 ? 'mac-loading' : ''}`}>
                  {renderContent()}
                </div>
                
                {/* Desktop Icons */}
                <div className="mac-desktop-icons">
                  <a href="/" onClick={handleIconClick('home')} className="mac-icon-link">
                    <div className="mac-icon">
                      <div className="mac-icon-image mac-icon-home"></div>
                      <div className="mac-icon-label">Home</div>
                    </div>
                  </a>
                  <a href="/skills" onClick={handleIconClick('skills')} className="mac-icon-link">
                    <div className="mac-icon">
                      <div className="mac-icon-image mac-icon-folder"></div>
                      <div className="mac-icon-label">Skills</div>
                    </div>
                  </a>
                  <a href="/projects" onClick={handleIconClick('projects')} className="mac-icon-link">
                    <div className="mac-icon">
                      <div className="mac-icon-image mac-icon-application"></div>
                      <div className="mac-icon-label">Projects</div>
                    </div>
                  </a>
                  <a href="/blog" onClick={handleIconClick('blog')} className="mac-icon-link">
                    <div className="mac-icon">
                      <div className="mac-icon-image mac-icon-document"></div>
                      <div className="mac-icon-label">Blog</div>
                    </div>
                  </a>
                  <a href="/contact" onClick={handleIconClick('contact')} className="mac-icon-link">
                    <div className="mac-icon">
                      <div className="mac-icon-image mac-icon-mail"></div>
                      <div className="mac-icon-label">Contact</div>
                    </div>
                  </a>
                </div>
                
                {/* Custom right-click context menu */}
                <MacContextMenu />
              </div>
              
              {/* CRT screen effects */}
              <div className="mac-crt-effect"></div>
              <div className="mac-screen-glare"></div>
            </div>
          </div>
          
          {/* Mac Computer Base with vents and nameplate */}
          <div className="mac-computer-base">
            <div className="mac-disc-drive"></div>
            <div className="mac-vents">
              {[...Array(15)].map((_, i) => (
                <div key={i} className="mac-vent"></div>
              ))}
            </div>
            <div className="mac-name-plate">
              Austin Barton • Macintosh 128K
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MacLayout;