import React, { useState } from 'react';
import MacWindow from '../components/ui/MacWindow';
import projects from '../data/projects';
import { FiGithub, FiExternalLink, FiFilter } from 'react-icons/fi';

const Projects = () => {
  const [selectedTag, setSelectedTag] = useState('All');
  
  // Extract all unique tags 
  const allTags = ['All', ...new Set(projects.flatMap(project => project.tags))];
  
  // Filter projects based on selected tag
  const filteredProjects = selectedTag === 'All' 
    ? projects 
    : projects.filter(project => project.tags.includes(selectedTag));

  return (
    <div className="mac-page mac-projects-page">
      <MacWindow 
        title="Projects.app" 
        initialPosition={{ top: 40, left: 60 }}
        zIndex={3}
        className="mac-projects-main-window"
      >
        <div className="mac-window-inner">
          <h1 className="mac-page-title">My Projects</h1>
          <p className="mac-page-subtitle">
            A showcase of my recent work and passion projects
          </p>
          
          <div className="mac-filter-section">
            <div className="mac-filter-label">
              <FiFilter className="mac-filter-icon" />
              <span>Filter by:</span>
            </div>
            <div className="mac-tag-filters">
              {allTags.map(tag => (
                <button
                  key={tag}
                  onClick={() => setSelectedTag(tag)}
                  className={`mac-tag-button ${selectedTag === tag ? 'mac-tag-button-active' : ''}`}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
          
          <div className="mac-projects-grid">
            {filteredProjects.length > 0 ? (
              filteredProjects.map(project => (
                <div key={project.id} className="mac-project-card">
                  <div className="mac-project-preview">
                    {/* In a real app, use actual images */}
                    <div className="mac-project-image-placeholder">
                      {project.title.split(' ').map(word => word[0]).join('')}
                    </div>
                  </div>
                  <div className="mac-project-info">
                    <h3 className="mac-project-title">{project.title}</h3>
                    <p className="mac-project-description">{project.description}</p>
                    
                    <div className="mac-project-tags">
                      {project.tags.map((tag, index) => (
                        <span key={index} className="mac-project-tag">
                          {tag}
                        </span>
                      ))}
                    </div>
                    
                    <div className="mac-project-links">
                      {project.githubUrl && (
                        <a 
                          href={project.githubUrl} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="mac-project-link"
                        >
                          <FiGithub className="mac-link-icon" />
                          <span>Source</span>
                        </a>
                      )}
                      {project.demoUrl && (
                        <a 
                          href={project.demoUrl} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="mac-project-link"
                        >
                          <FiExternalLink className="mac-link-icon" />
                          <span>Demo</span>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="mac-no-projects">
                <p>No projects match the selected filter.</p>
              </div>
            )}
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="Project_Details.txt" 
        initialPosition={{ top: 80, left: 100 }}
        zIndex={2}
        className="mac-project-details-window"
      >
        <div className="mac-text-file">
          <p>Project Portfolio Information</p>
          <p>---------------------------</p>
          <p>This is a showcase of my personal and professional projects:</p>
          <p>• Each project includes source code links where available</p>
          <p>• You can filter projects by technology using the filter buttons</p>
          <p>• Click on any project card to see more details</p>
          <p>• Demo links will take you to live versions of the projects</p>
          <p>• Projects range from web applications to utilities and tools</p>
        </div>
      </MacWindow>
    </div>
  );
};

export default Projects;