import React, { useState, useRef, useEffect } from 'react';

const MacWindow = ({ title, children, className = '', initialPosition = { top: 50, left: 50 }, zIndex = 1 }) => {
  const [position, setPosition] = useState(initialPosition);
  const [isDragging, setIsDragging] = useState(false);
  const [clickPos, setClickPos] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(true);
  const [windowZIndex, setWindowZIndex] = useState(zIndex);
  const [screenBounds, setScreenBounds] = useState({ width: 0, height: 0 });
  
  const windowRef = useRef(null);
  
  // Get screen dimensions on mount and window resize
  useEffect(() => {
    const updateScreenBounds = () => {
      const desktopContent = document.querySelector('.mac-desktop-content');
      if (desktopContent) {
        setScreenBounds({
          width: desktopContent.clientWidth,
          height: desktopContent.clientHeight
        });
      }
    };
    
    // Initial measurement
    updateScreenBounds();
    
    // Update on resize
    window.addEventListener('resize', updateScreenBounds);
    return () => window.removeEventListener('resize', updateScreenBounds);
  }, []);
  
  // Handle window mouse dragging
  const handleMouseDown = (e) => {
    // Don't initiate drag if clicking on buttons or if window is hidden
    if (e.target.closest('.mac-window-close') || !isVisible) {
      return;
    }
    
    // Update Z-index to bring window to front
    setWindowZIndex(999);
    
    // Set dragging state
    setIsDragging(true);
    
    // Store the initial click position and window position
    setClickPos({
      x: e.clientX - position.left,
      y: e.clientY - position.top
    });
    
    e.preventDefault();
  };

  // Handle touch start for mobile devices
  const handleTouchStart = (e) => {
    // Don't initiate drag if touching on buttons or if window is hidden
    if (e.target.closest('.mac-window-close') || !isVisible) {
      return;
    }
    
    // Update Z-index to bring window to front
    setWindowZIndex(999);
    
    // Set dragging state
    setIsDragging(true);
    
    // Store the initial touch position and window position
    const touch = e.touches[0];
    setClickPos({
      x: touch.clientX - position.left,
      y: touch.clientY - position.top
    });
    
    // Don't prevent default here to allow scrolling inside the window
  };
  
  // Handle mouse move and touch move with simple screen constraints
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging || !windowRef.current) return;
      
      // Calculate the potential new position
      let newLeft = e.clientX - clickPos.x;
      let newTop = e.clientY - clickPos.y;
      
      // Apply constraints to keep window within screen bounds
      applyPositionConstraints(newLeft, newTop);
    };

    const handleTouchMove = (e) => {
      if (!isDragging || !windowRef.current) return;
      
      // Get the first touch
      const touch = e.touches[0];
      
      // Calculate the potential new position
      let newLeft = touch.clientX - clickPos.x;
      let newTop = touch.clientY - clickPos.y;
      
      // Apply constraints to keep window within screen bounds
      applyPositionConstraints(newLeft, newTop);
      
      // Prevent default to stop scrolling while dragging
      e.preventDefault();
    };
    
    const applyPositionConstraints = (newLeft, newTop) => {
      // Get window dimensions
      const windowWidth = windowRef.current.offsetWidth;
      const windowHeight = windowRef.current.offsetHeight;
      
      // Apply constraints to keep window within screen bounds
      // Allow a minimum of 80px of the window to always be visible
      const minVisibleWidth = Math.min(80, windowWidth);
      const minVisibleHeight = Math.min(30, windowHeight);
      
      // Calculate boundaries
      newLeft = Math.max(-windowWidth + minVisibleWidth, Math.min(newLeft, screenBounds.width - minVisibleWidth));
      newTop = Math.max(0, Math.min(newTop, screenBounds.height - minVisibleHeight));
      
      // Set new position
      setPosition({ left: newLeft, top: newTop });
    };
    
    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
    };
    
    if (isDragging) {
      // Add mouse event listeners
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      
      // Add touch event listeners
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleTouchEnd);
      window.addEventListener('touchcancel', handleTouchEnd);
    }
    
    return () => {
      // Remove mouse event listeners
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      
      // Remove touch event listeners
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchcancel', handleTouchEnd);
    };
  }, [isDragging, clickPos, screenBounds, position]);
  
  // Handle close button
  const handleClose = () => {
    setIsVisible(false);
  };
  
  // If window is not visible, don't render anything
  if (!isVisible) {
    return null;
  }

  return (
    <div 
      ref={windowRef}
      className={`mac-window ${className}`}
      style={{ 
        left: `${position.left}px`,
        top: `${position.top}px`,
        zIndex: windowZIndex
      }}
    >
      <div 
        className="mac-window-title-bar"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <div className="mac-window-title">{title}</div>
        <div className="mac-window-controls">
          <button className="mac-window-close" onClick={handleClose}></button>
        </div>
      </div>
      <div className="mac-window-content">
        {children}
      </div>
    </div>
  );
};

export default MacWindow;