import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const MacContextMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const menuRef = useRef(null);
  const navigate = useNavigate();
  
  // Menu items data
  const menuItems = [
    { label: 'New Window', action: () => console.log('New Window'), disabled: true },
    { divider: true },
    { label: 'Navigate Home', action: () => navigate('/') },
    { label: 'View Projects', action: () => navigate('/projects') },
    { label: 'Explore Skills', action: () => navigate('/skills') },
    { label: 'Read Blog', action: () => navigate('/blog') },
    { label: 'Contact Me', action: () => navigate('/contact') },
    { divider: true },
    { label: 'Visit GitHub', action: () => window.open('https://github.com/austinbarton1997', '_blank') },
    { label: 'Visit LinkedIn', action: () => window.open('https://www.linkedin.com/in/austin-barton-909844268', '_blank') },
  ];

  // Handle right click anywhere in the Mac desktop content
  useEffect(() => {
    const handleContextMenu = (e) => {
      // Only show custom context menu inside mac-desktop-content
      if (document.querySelector('.mac-desktop-content')?.contains(e.target)) {
        e.preventDefault();
        
        // Calculate position, keeping the menu within viewport
        const clickX = e.clientX;
        const clickY = e.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const menuWidth = 200;  // approximate menu width
        const menuHeight = menuItems.length * 25 + 20;  // approximate menu height
        
        // Adjust position if menu would go off screen
        const left = clickX + menuWidth > screenW ? clickX - menuWidth : clickX;
        const top = clickY + menuHeight > screenH ? clickY - menuHeight : clickY;
        
        setPosition({ left, top });
        setIsVisible(true);
      }
    };
    
    // Hide the menu when clicking elsewhere
    const handleClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsVisible(false);
      }
    };
    
    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('mousedown', handleClick);
    
    // Clean up event listeners
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [menuItems.length]);
  
  // Handle menu item click
  const handleMenuItemClick = (action) => {
    action();
    setIsVisible(false);
  };
  
  if (!isVisible) return null;

  return (
    <div 
      ref={menuRef}
      className="mac-context-menu"
      style={{
        left: `${position.left}px`,
        top: `${position.top}px`
      }}
    >
      {menuItems.map((item, index) => {
        if (item.divider) {
          return <div key={`divider-${index}`} className="mac-menu-divider"></div>;
        }
        
        return (
          <div 
            key={`item-${index}`}
            className={`mac-menu-item ${item.disabled ? 'mac-menu-item-disabled' : ''}`}
            onClick={() => !item.disabled && handleMenuItemClick(item.action)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default MacContextMenu;