import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import projects from '../../data/projects';

const ProjectGrid = ({ limit, filter }) => {
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const [selectedTag, setSelectedTag] = useState('All');
  
  // Extract all unique tags from projects
  const allTags = ['All', ...new Set(projects.flatMap(project => project.tags))];

  useEffect(() => {
    // Apply filtering logic
    let filteredProjects = [...projects];
    
    // Apply tag filter if not "All"
    if (selectedTag !== 'All') {
      filteredProjects = filteredProjects.filter(project => 
        project.tags.includes(selectedTag)
      );
    }
    
    // Apply custom filter if provided
    if (filter) {
      filteredProjects = filteredProjects.filter(filter);
    }
    
    // Apply limit if provided
    if (limit) {
      filteredProjects = filteredProjects.slice(0, limit);
    }
    
    setDisplayedProjects(filteredProjects);
  }, [limit, filter, selectedTag]);

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
  };

  return (
    <div className="mac-projects-container">
      {/* Filter tags - only shown when no limit is specified (full projects page) */}
      {!limit && (
        <div className="mac-tag-filters-container">
          <div className="mac-tag-filters-scroll">
            {allTags.map((tag, index) => (
              <button
                key={index}
                onClick={() => handleTagChange(tag)}
                className={`mac-tag-button ${
                  selectedTag === tag ? 'mac-tag-button-active' : ''
                }`}
              >
                {tag}
              </button>
            ))}
          </div>
        </div>
      )}
      
      {/* Projects grid */}
      <div className="mac-project-grid">
        {displayedProjects.length > 0 ? (
          displayedProjects.map(project => (
            <div key={project.id} className="mac-project-item">
              <ProjectCard project={project} />
            </div>
          ))
        ) : (
          <div className="mac-no-projects">
            <p>No projects match the selected filter.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectGrid;