import React from 'react';
import { FiGithub, FiExternalLink } from 'react-icons/fi';

const ProjectCard = ({ project }) => {
  return (
    <div className="mac-project-card">
      {/* Project Image */}
      <div className="mac-project-image">
        {/* In a real project, you would use a real image here */}
        {/* If you've added your images to the public folder, you could use: */}
        {/* <img src={project.image} alt={project.title} className="w-full h-full object-cover" /> */}
        <div className="mac-project-image-placeholder">
          {project.title.split(' ').map(word => word[0]).join('')}
        </div>
      </div>
      
      {/* Project Content */}
      <div className="mac-project-content">
        <h3 className="mac-project-title">{project.title}</h3>
        <p className="mac-project-description">{project.description}</p>
        
        <div className="mac-project-tags">
          {project.tags.map((tag, index) => (
            <span key={index} className="mac-project-tag">
              {tag}
            </span>
          ))}
        </div>
        
        <div className="mac-project-links">
          {project.githubUrl && (
            <a 
              href={project.githubUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="mac-project-link"
              aria-label={`GitHub repository for ${project.title}`}
            >
              <FiGithub className="mac-project-icon" />
              <span>Source</span>
            </a>
          )}
          {project.demoUrl && (
            <a 
              href={project.demoUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="mac-project-link"
              aria-label={`Live demo for ${project.title}`}
            >
              <FiExternalLink className="mac-project-icon" />
              <span>Demo</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;