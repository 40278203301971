import React from 'react';
import MacWindow from '../components/ui/MacWindow';
import ContactForm from '../components/contact/ContactForm';
import { FiMail, FiMapPin, FiPhone, FiGithub, FiLinkedin} from 'react-icons/fi';

const Contact = () => {
  return (
    <div className="mac-page mac-contact-page">
      <MacWindow 
        title="Contact.form" 
        initialPosition={{ top: 40, left: 60 }}
        zIndex={3}
        className="mac-contact-form-window"
      >
        <div className="mac-window-inner">
          <h1 className="mac-page-title">Get In Touch</h1>
          <p className="mac-page-subtitle">
            Have a project in mind? Let's discuss how I can help bring your ideas to life.
          </p>
          
          <ContactForm />
        </div>
      </MacWindow>
      
      <MacWindow 
        title="Contact_Info.txt" 
        initialPosition={{ top: 80, left: 805 }}
        zIndex={2}
        className="mac-contact-info-window"
      >
        <div className="mac-contact-info">
          <div className="mac-contact-item">
            <FiMail className="mac-contact-icon" />
            <div className="mac-contact-detail">
              <h3 className="mac-contact-label">Email</h3>
              <a href="mailto:mail@austinbarton.dev" className="mac-contact-value">
              mail@austinbarton.dev
              </a>
            </div>
          </div>
          
    
          
          <div className="mac-contact-item">
            <FiMapPin className="mac-contact-icon" />
            <div className="mac-contact-detail">
              <h3 className="mac-contact-label">Location</h3>
              <p className="mac-contact-value">
                Chicago, IL
              </p>
            </div>
          </div>
          
          <div className="mac-social-links">
            <h3 className="mac-social-title">Connect with me</h3>
            <div className="mac-social-buttons">
              <a 
                href="https://github.com/austinbarton1997" 
                target="_blank" 
                rel="noopener noreferrer"
                className="mac-social-button"
              >
                <FiGithub className="mac-social-icon" />
                <span>GitHub</span>
              </a>
              
              <a 
                href="https://www.linkedin.com/in/austin-barton-909844268" 
                target="_blank" 
                rel="noopener noreferrer"
                className="mac-social-button"
              >
                <FiLinkedin className="mac-social-icon" />
                <span>LinkedIn</span>
              </a>
              
            </div>
          </div>
        </div>
      </MacWindow>
      
      <MacWindow 
        title="Response_Time.txt" 
        initialPosition={{ top: 120, left: 140 }}
        zIndex={1}
        className="mac-response-time-window"
      >
        <div className="mac-text-file">
          <p>Contact Response Information</p>
          <p>--------------------------</p>
          <p>Thank you for reaching out!</p>
          <p>I typically respond to all inquiries within 24-48 hours.</p>
          <p>I'm currently available for:</p>
          <p>• Freelance projects</p>
          <p>• Consulting work</p>
          <p>• Full-time opportunities</p>
        </div>
      </MacWindow>
    </div>
  );
};

export default Contact;