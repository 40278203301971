import React from 'react';
import { Link } from 'react-router-dom';
import MacWindow from '../components/ui/MacWindow';
import ProjectGrid from '../components/projects/ProjectGrid';
import ContactForm from '../components/contact/ContactForm';

const Home = () => {
  return (
    <div className="mac-page mac-home-page">
      {/* Welcome/Introduction Window */}
      <MacWindow 
        title="Welcome.txt" 
        initialPosition={{ top: 10, left: 30 }}
        zIndex={4}
        className="mac-welcome-window"
      >
        <div className="mac-welcome-content">
          <h1 className="mac-welcome-title">
            Hi, I'm <span>Austin Barton</span>
          </h1>
          <p className="mac-welcome-text">
            A full-stack developer specializing in building exceptional web applications 
            with modern technologies and a focus on user experience.
          </p>
          <div className="mac-welcome-buttons">
            <Link to="/projects" className="mac-button">
              View My Work
            </Link>
            <Link to="/contact" className="mac-button mac-button-outline">
              Let's Connect
            </Link>
          </div>
        </div>
      </MacWindow>
      
      {/* Skills Window */}
      <MacWindow 
        title="Skills.txt" 
        initialPosition={{ top: 30, left: 70 }}
        zIndex={3}
        className="mac-skills-window"
      >
        <div className="mac-skills-content">
          <h2 className="mac-section-title">SKILLS</h2>
          
          <div className="mac-skills-grid">
            <MacSkillCategory 
              title="Frontend"
              skills={['React', 'Next.js', 'HTML5/CSS3', 'JavaScript', 'TypeScript', 'Tailwind CSS']}
            />
            <MacSkillCategory 
              title="Backend"
              skills={['Node.js', 'Express', 'Python', 'Java', 'REST APIs']}
            />
            <MacSkillCategory 
              title="Database"
              skills={['MongoDB', 'PostgreSQL', 'MySQL', 'Firebase', 'Redis', 'ORM']}
            />
            <MacSkillCategory 
              title="Tools"
              skills={['Git', 'Docker', 'AWS', 'CI/CD', 'Testing', 'Agile']}
            />
          </div>
          
          <div className="mac-view-more text-center mt-4">
            <Link to="/skills" className="mac-button">
              View All Skills
            </Link>
          </div>
        </div>
      </MacWindow>
      
      {/* Projects Window */}
      <MacWindow 
        title="Projects.app" 
        initialPosition={{ top: 50, left: 110 }}
        zIndex={2}
        className="mac-projects-window"
      >
        <div className="mac-projects-content">
          <h2 className="mac-section-title">PROJECTS</h2>
          <p className="mac-projects-description">
            A showcase of my recent work and passion projects
          </p>
          
          <div className="mac-project-grid">
            <ProjectGrid limit={5} />
          </div>
          
          <div className="mac-view-more">
            <Link to="/projects" className="mac-button">
              View All Projects
            </Link>
          </div>
        </div>
      </MacWindow>
      
      {/* Contact Window */}
      <MacWindow 
        title="Contact.form" 
        initialPosition={{ top: 70, left: 150 }}
        zIndex={1}
        className="mac-contact-window"
      >
        <div className="mac-contact-content">
          <h2 className="mac-section-title">LET'S WORK TOGETHER</h2>
          <p className="mac-contact-description">
            Have a project in mind? Let's discuss how I can help bring your ideas to life.
          </p>
          
          <div className="mac-contact-form-compact">
            <ContactForm compact={true} />
          </div>
          
          <div className="text-center mt-3">
            <Link to="/contact" className="mac-button">
              Contact Details
            </Link>
          </div>
        </div>
      </MacWindow>
      
      {/* About Me Note Window - This is an additional window */}
      <MacWindow 
        title="About_Me.txt" 
        initialPosition={{ top: 90, left: 190 }}
        zIndex={0}
        className="mac-about-window"
      >
        <div className="mac-text-file">
          <p>ABOUT AUSTIN BARTON</p>
          <p>-------------------</p>
          <p>I'm a passionate Full-Stack Developer with expertise in building web applications 
             that deliver exceptional user experiences.</p>
          <p></p>
          <p>My approach combines technical expertise with creative problem-solving to create 
             solutions that not only meet but exceed expectations.</p>
          <p></p>
          <p>When I'm not coding, you can find me skating with my dog, playing disc golf, 
             and climbing.</p>
        </div>
      </MacWindow>
    </div>
  );
};

// Mac Skill Category Component
const MacSkillCategory = ({ title, skills }) => {
  return (
    <div className="mac-skill-category">
      <h3 className="mac-skill-title">{title}</h3>
      <ul className="mac-skill-list">
        {skills.map((skill, index) => (
          <li key={index} className="mac-skill-item">
            <span className="mac-bullet">●</span>
            {skill}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Home;